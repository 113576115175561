import { Modal } from "../../../components/Modal/Modal";
import { MemberData } from "../../../types/MemberData";
import { ModalHeader } from "../../../components/Modal/ModalHeader";
import { ModalBody } from "../../../components/Modal/ModalBody";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {authenticatedFetch} from "../../../service/api";
import {Spinner} from "../../../components/Spinner";
import {SearchBar} from "../../../components/search/SearchBar";
import {useState} from "react";
import {useI18n} from "../../../translation";
import {Button} from "../../../components/Button";
import {CircleButton} from "../../../components/CircleButton";
import {FaPlus} from "react-icons/fa";

const MemberHomeworkSearchResult = ({searchQuery, memberId, sitzkriegerId}: {searchQuery: string, memberId: string, sitzkriegerId: number}) => {
  const queryClient = useQueryClient();

  const i18n = useI18n();
  const { data , isLoading } = useQuery<{id: number, title: string, data: {type: string, video: number, thumbnail: string}, product: string, internalDescriptionForTrainer: string}[]>(
    ["memberHomeworkSearch", searchQuery],
    async () => {
      const response = await authenticatedFetch("member/" + memberId + "/search-video?" +
        new URLSearchParams({
          query: searchQuery,
        }));
      if (!response.ok) throw new Error();
      const {videos} = await response.json();
      return videos;
    }
  );

  const addToPlaylistMutation = useMutation(
    async (videoId: number) => {
      const response = await authenticatedFetch("member/" + memberId + '/playlist/' + videoId, 'POST');
      if (!response.ok) throw new Error();
      return response.json();
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["memberHomework", sitzkriegerId]);
      },
      onError: async () => {
      },
    }
  );

  if (isLoading) return <Spinner className="self-center w-8 h-8 my-4" />;
  const videosFound = data && data.length > 0;
  if (!videosFound) {
    return <p className="self-center text-gray-500 py-5">
      {i18n["search.noResults"]}
    </p>
  }

  return <>
  {data.map((video) => {
    return <div key={video.id}>
      <div className="flex flex-row my-3">
        <img src={video.data.thumbnail} width={150} height={85} alt={video.title}/>
        <div className="mx-1 flex flex-col">
          {video.id}: {video.title}
          <div>
            <Button
              title={i18n['memberModal.sitzkriegerHomework.addToPlaylist']}
              onClick={() => addToPlaylistMutation.mutate(video.id)}
              isLoading={addToPlaylistMutation.isLoading}
            />
            &nbsp;
            <a className="text-brand-primary"
               href={process.env.REACT_APP_SITZKRIEGER_HOST + '/w/' + video.id}
               target="_blank" rel="noreferrer" title="video detail page">
              Video
            </a>
          </div>
        </div>
      </div>
      <div className="border-b border-b-1">
        {video.internalDescriptionForTrainer}
      </div>
    </div>;
  })}
  </>
}

export const MemberHomeworkModal = ({
                                      label,
                                      closeModal,
                                      member,
                                    }: {
  label: string;
  closeModal: () => void;
  member: MemberData,
}) => {
  const queryClient = useQueryClient();
  const i18n = useI18n();
  const [searchText, setSearchText] = useState("");
  const {isLoading, data} = useQuery<{id: number, title: string, type: 'video', video: number, thumbnail: string}[]>(
    ["memberHomework", member.sitzkriegerId],
    async () => {
      const response = await authenticatedFetch("member/" + member.id + "/playlist");
      if (!response.ok) throw new Error();
      const {videos} = await response.json();
      return videos;
    }
  );

  const removeFromPlaylistMutation = useMutation(
    async (videoId: number) => {
      const response = await authenticatedFetch("member/" + member.id + '/playlist/' + videoId, 'DELETE');
      if (!response.ok) throw new Error();
      return response.json();
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["memberHomework", member.sitzkriegerId]);
      },
      onError: async () => {
      },
    }
  );

  return (
    <Modal className="max-w-[80%]" closeModal={closeModal}>
        <ModalHeader title={label} closeModal={closeModal} />
        <ModalBody>
          <div className="flex flex-row gap-y-6">
            <div className="w-1/2">
              {data && ((data?.length || 0) > 0) ? <>
                <h5 className="text-xl font-medium leading-normal text-gray-800">
                  {i18n['memberModal.sitzkriegerHomework.videosInPlaylist']}
                </h5>
                {data.map((item) => {
                  return <div className="flex flex-row my-3 mr-2 border border-1 p-2 justify-between" key={item.id}>
                    <div>
                      <b>{item.id}: {item.title}</b>
                    </div>
                    <div>
                      <CircleButton
                        icon={<FaPlus />}
                        onClick={() => removeFromPlaylistMutation.mutate(item.id)}
                        className={"rotate-45"}
                      />
                    </div>
                  </div>
                })}
              </> : <>{i18n['memberModal.sitzkriegerHomework.noVideosInPlaylist']}</>}
            </div>
            {isLoading ? <Spinner className="absolute inset-0 m-auto w-6 h-6"/> : <div className="w-1/2">
              <SearchBar
                className="[&_input]:py-4"
                searchText={searchText}
                setSearchText={setSearchText}
                isDisabled={false}
              >
                <MemberHomeworkSearchResult searchQuery={searchText} memberId={member.id}
                                            sitzkriegerId={member.sitzkriegerId}/>
              </SearchBar>
            </div>}
          </div>
        </ModalBody>
    </Modal>
  );
};
