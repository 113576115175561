import {ReactElement} from "react";
import {useAuth} from "../pages/layout/Authentication";

export const Style = (): ReactElement => {
  const auth = useAuth();
  if (auth.trainer === null) {
    return <></>;
  }

  let primary = `0, 83, 126`;
  let secondary = `19, 143, 56`;

  if ((auth.trainer.studio.primaryColorString || '') !== '') {
    primary = auth.trainer.studio.primaryColorString!;
  }
  if ((auth.trainer.studio.secondaryColorString || '') !== '') {
    primary = auth.trainer.studio.secondaryColorString!;
  }

  // let c = '#d02428';

  let style = `
    :root {
      --color-primary: ${primary};
      --color-secondary: ${secondary};
    }
  `;

  return <style>{style}</style>;
}
